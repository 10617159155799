/* globals ajax */

import './vendor/ajax.min.js';
import { breakpoints } from '../variables.yml';
import 'focus-visible';
import { getWindowWidth, getWindowHeight, throttle } from './helpers.js';
import EmblaCarousel from 'embla-carousel';
import { setupPrevNextBtns, disablePrevNextBtns } from './vendor/prevAndNextButtons.js';
// import './vendor/lib.js';
// import { whatever } from './vendor/lib.js';

import { selectTickets } from './vue/select-tickets.js';

export * from './CookieConsentManager.js';
export * from './search.js';
export * from './vendor/datalist-css.min.js';

( () => {

	// Scroll back to top ( on particularly long pages )

	const top_link = document.querySelector( '.back-to-top' );

	if ( !top_link ) {
		return;
	}

	window.addEventListener( 'load', () => {
		backToTopLink();
	} );

	window.addEventListener( 'scroll', throttle( () => {
		backToTopLink();
	} ), 50 );

	window.addEventListener( 'resize', throttle( () => {
		backToTopLink();
	} ), 50 );

	function backToTopLink() {
		const document_height = document.body.scrollHeight;
		const window_height_twice = ( getWindowHeight() * 2 );
		const scroll_y_pos = window.scrollY;

		if ( document_height > window_height_twice ) {
			top_link.classList.add( 'back-to-top--prepare' );

			if ( scroll_y_pos > window_height_twice ) {
				top_link.classList.add( 'back-to-top--visible' );
			} else {
				top_link.classList.remove( 'back-to-top--visible' );
			}

		} else {
			top_link.classList.remove( 'back-to-top--prepare' );
		}

		top_link.addEventListener( 'click', ( e ) => {
			e.preventDefault();
			window.scrollTo( 0, 0 );
		} );
	}

} )();

( () => {
	// Video
	const videos = document.querySelectorAll( '.js-video' );

	if ( !videos.length > 0 ) {
		return;
	}

	function buildVideoPlayer( container ) {
		const video_poster = container.querySelector( '.video__poster-wrapper' );
		const video_embed = container.querySelector( '.video__embed' );

		if ( !video_poster || !video_embed ) {
			return;
		}

		// Create the iframe from details, if this exists
		const video_iframe_details = video_embed.querySelector( '.video__iframe-details' );

		if ( video_iframe_details ) {
			const attributes_object = {};
			for ( const attrib of video_iframe_details.attributes ) {
				if ( /^data-/.test( attrib.name ) === true ) {
					attributes_object[attrib.name.substring( 5 )] = attrib.value;
				}
			}

			const iframe = document.createElement( 'iframe' );
			video_embed.appendChild( iframe );

			for ( const property in attributes_object ) {
				iframe.setAttribute( property, attributes_object[property] );
			}

			video_iframe_details.remove();
		}

		const video_iframe = video_embed.querySelector( 'iframe' );

		if ( !video_iframe ) {
			return;
		}

		video_poster.classList.add( 'video__poster-wrapper--hidden' );
		video_embed.classList.remove( 'video__embed--hidden' );
		let src = video_iframe.getAttribute( 'src' );
		src = src.replace( 'autoplay=0', 'autoplay=1' );
		video_iframe.setAttribute( 'src', src );
	}

	function handleVideoClick( container ) {
		return ( event ) => {
			if ( event.preventDefault ) {
				event.preventDefault();
			} else {
				event.returnValue = false;
			}

			buildVideoPlayer( container );
		};
	}

	for ( let i = 0; i < videos.length; i++ ) {
		videos[i].addEventListener( 'click', handleVideoClick( videos[i] ) );
	}
} )();

( () => {

	// Drop-down navigation

	const nav = document.querySelector( '.header-nav__top-level' );

	if ( !nav ) {
		return;
	}

	const nav_links = nav.querySelectorAll( '.header-nav__link[data-sub-nav]' );
	const sub_navs = nav.querySelectorAll( '.header-nav__subnav' );

	if ( !nav_links.length > 0 || !sub_navs.length > 0 ) {
		return;
	}

	// Open / Close drop-down navigation

	// Desktop

	nav_links.forEach( nav_link => {

		nav_link.addEventListener( 'mouseover', ( event ) => {
			if ( breakpoints.d.width >= getWindowWidth() ) {
				return;
			}

			closeAllSubNavs( true );
			closeSearchForm();

			const nav_link = event.target;
			openSubNav( nav_link );

			setTimeout( () => {
				nav.classList.add( 'header-nav__top-level--no-flicker' );
			}, 300 );
		} );

		// Allow return key events for keyboard users
		nav_link.addEventListener( 'keydown', ( event ) => {
			if ( breakpoints.d.width >= getWindowWidth() ) {
				return;
			}

			if ( event.keyCode === 13 ) {
				// prevent being able to "click" the top-level link
				event.preventDefault();

				closeAllSubNavs( true );
				closeSearchForm();

				const nav_link = event.target;
				openSubNav( nav_link );

			}
		} );

		// Prevent click on items that have no-click class, e.g. CCT Digital, and Shop
		nav_link.addEventListener( 'click', ( event ) => {
			if ( event.target.classList.contains( 'header-nav__link--no-click' ) ) {
				event.preventDefault();
			}
		} );

	} );

	sub_navs.forEach( sub_nav => {
		sub_nav.addEventListener( 'mouseover', ( event ) => {
			if ( event.target.classList.contains( 'header-nav__subnav--open' ) ) {
				closeAllSubNavs( false );
			}
		} );
	} );

	document.addEventListener( 'keyup', ( event ) => {
		if ( ( document.body.classList.contains( 'subnav-opening' ) === true ) && ( event.key === 'Escape' ) ) {
			if ( breakpoints.d.width >= getWindowWidth() ) {
				return;
			}
			closeAllSubNavs( false );
		}
	} );

	// Mobile

	nav_links.forEach( nav_link => {
		nav_link.addEventListener( 'click', ( event ) => {
			if ( breakpoints.d.width < getWindowWidth() ) {
				return;
			}
			event.preventDefault();

			if ( event.target.classList.contains( 'header-nav__link--subnav-open' ) || event.target.classList.contains( 'header-nav__link--subnav-preopen' ) ) {
				closeAllSubNavs( false );
			} else {
				closeAllSubNavs( true );

				const nav_link = event.target;
				openSubNav( nav_link );
			}

		} );
	} );

	function openSubNav( nav_link ) {
		// Add open link style
		nav_link.classList.add( 'header-nav__link--subnav-open' );

		// Get the Subnav ID and open it
		const id = nav_link.dataset.subNav;
		const show_sub_nav = document.getElementById( id );
		show_sub_nav.classList.add( 'header-nav__subnav--opening' );
		setTimeout( () => {
			show_sub_nav.classList.add( 'header-nav__subnav--open' );
		}, 10 );

		// Set max-height as inline style
		if ( breakpoints.d.width < getWindowWidth() ) {
			// Remove max-height from inline style if desktop
			show_sub_nav.style.maxHeight = null;
		} else {
			// Set max-height as inline style if mobile
			show_sub_nav.style.maxHeight = show_sub_nav.scrollHeight + 'px';
		}

		document.body.classList.add( 'subnav-opening' );
	}

} )();

function closeAllSubNavs( keep_opening ) {

	const nav = document.querySelector( '.header-nav__top-level' );
	const nav_links = document.querySelectorAll( '.header-nav__link[data-sub-nav]' );
	const sub_navs = document.querySelectorAll( '.header-nav__subnav' );

	if ( !nav || !nav_links.length > 0 || !sub_navs.length > 0 ) {
		return;
	}

	// Remove any open link styles
	nav_links.forEach( nav_link => {
		nav_link.classList.remove( 'header-nav__link--subnav-open' );
		nav_link.classList.remove( 'header-nav__link--subnav-preopen' );
	} );

	// Close any open subnavs
	sub_navs.forEach( sub_nav => {
		sub_nav.classList.remove( 'header-nav__subnav--open' );
		sub_nav.classList.remove( 'header-nav__subnav--preopen' );
		if ( keep_opening === true ) {
			setTimeout( () => {
				sub_nav.classList.add( 'header-nav__subnav--opening' );
			}, 300 );
		} else {
			nav.classList.remove( 'header-nav__top-level--no-flicker' );
			document.body.classList.remove( 'subnav-opening' );
			setTimeout( () => {
				sub_nav.classList.remove( 'header-nav__subnav--opening' );
			}, 300 );
		}
		// Remove max-height from inline styles
		sub_nav.style.maxHeight = null;
	} );

}



( () => {

	// Mobile navigation open / close

	const menu_buttons = document.querySelectorAll( '.js-mobile-menu' );

	if ( !menu_buttons.length > 0 ) {
		return;
	}

	menu_buttons.forEach( menu_button => {
		menu_button.addEventListener( 'click', () => {
			if ( document.body.classList.contains( 'mobile-nav-open' ) ) {
				document.body.classList.remove( 'mobile-nav-open' );
				setTimeout( () => {
					document.body.classList.remove( 'mobile-nav-opening' );
					document.body.classList.remove( 'subnav-opening' );
				}, 300 );

			} else {
				document.body.classList.add( 'mobile-nav-opening' );
				window.scrollTo( 0, 0 );
				setTimeout( () => {
					document.body.classList.add( 'mobile-nav-open' );
					document.body.classList.add( 'subnav-opening' );
				}, 10 );
				setPreOpenSubNav();
			}
		} );
	} );

	function setPreOpenSubNav() {
		const preopen_sub_nav = document.querySelector( '.header-nav__subnav--preopen' );
		if ( !preopen_sub_nav ) {
			return;
		}

		// Set max-height as inline style
		if ( breakpoints.e.width < getWindowWidth() ) {
			// Remove max-height from inline style if desktop
			preopen_sub_nav.style.maxHeight = null;
		} else {
			// Set max-height as inline style if mobile
			preopen_sub_nav.style.maxHeight = preopen_sub_nav.scrollHeight + 'px';
			preopen_sub_nav.classList.add( 'header-nav__subnav--opening' );
			preopen_sub_nav.classList.add( 'header-nav__subnav--open' );
		}
	}

} )();

( () => { // Embla header image carousel
	const embla_nodes = document.querySelectorAll( '.embla' );

	if ( !embla_nodes.length > 0 ) {
		return;
	}

	embla_nodes.forEach( embla_node => {

		const options = { loop: true };
		const embla_api = EmblaCarousel( embla_node, options );
		const prev_btn = embla_node.querySelector( '.embla__button--prev' );
		const next_btn = embla_node.querySelector( '.embla__button--next' );

		if ( prev_btn && next_btn ) {
			const disable_prev_and_next_btns = disablePrevNextBtns( prev_btn, next_btn, embla_api );
			setupPrevNextBtns( prev_btn, next_btn, embla_api );

			embla_api.on( 'select', disable_prev_and_next_btns );
			embla_api.on( 'init', disable_prev_and_next_btns );
			embla_api.on( 'reInit', disable_prev_and_next_btns );
		}

		const carousel_counter = embla_node.querySelector( '.carousel-active-index' );
		if ( !carousel_counter ) {
			return;
		}

		embla_api.on( 'scroll', ()=> {
			carousel_counter.textContent = embla_api.selectedScrollSnap() + 1;
		} );

	} );

} )();

( () => {

	// Header search show / hide

	const header_search = document.querySelector( '.header-search' );
	const header_search_button = document.querySelector( '.header-nav__link--search' );

	if ( !header_search || !header_search_button ) {
		return;
	}

	header_search_button.addEventListener( 'click', () => {
		if ( header_search_button.classList.contains( 'header-nav__link--search--open' ) ) {
			closeSearchForm();
			header_search_button.focus();
		} else {
			openSearchForm();
		}
	} );

	function openSearchForm() {
		header_search.classList.add( 'header-search--opening' );
		setTimeout( () => {
			header_search.classList.add( 'header-search--open' );
		}, 10 );
		document.querySelector( '.header-search__input' ).focus();
		header_search_button.classList.add( 'header-nav__link--search--open' );
		document.body.classList.add( 'show-search-header' );

		closeAllSubNavs( false );
	}

	const header_search_shadow = document.querySelector( '.header-search__shadow' );

	if ( !header_search_shadow ) {
		return;
	}

	header_search_shadow.addEventListener( 'mouseover' , () => {
		closeSearchForm();
	} );

	const header_search_close_btn = document.querySelector( '.header-search__form-close' );

	if ( !header_search_close_btn ) {
		return;
	}

	header_search_close_btn.addEventListener( 'click' , () => {
		closeSearchForm();
		header_search_button.focus();
	} );

	document.addEventListener( 'keyup', ( event ) => {
		if ( ( document.body.classList.contains( 'show-search-header' ) === true ) && ( event.key === 'Escape' ) ) {
			if ( breakpoints.d.width >= getWindowWidth() ) {
				return;
			}
			closeSearchForm();
			header_search_button.focus();
		}
	} );

} )();

function closeSearchForm() {

	const header_search = document.querySelector( '.header-search' );
	const header_search_button = document.querySelector( '.header-nav__link--search' );

	if ( !header_search || !header_search_button ) {
		return;
	}

	header_search.classList.remove( 'header-search--open' );
	setTimeout( () => {
		header_search.classList.remove( 'header-search--opening' );
	}, 300 );
	header_search_button.classList.remove( 'header-nav__link--search--open' );

	// Close the results if open
	const search_results_wrapper = document.querySelector( '.search-results' );
	if ( !search_results_wrapper ) {
		return;
	}
	search_results_wrapper.classList.add( 'search-results--hidden' );
	document.body.classList.remove( 'show-search-header' );
}

( () => {

	// Characters remaining on input fields with maxlength

	const limited_inputs = document.querySelectorAll( '.js-input-has-limit' );
	const chars_wrappers = document.querySelectorAll( '.js-input-chars' );

	if ( !limited_inputs.length > 0 || !chars_wrappers.length > 0 ) {
		return;
	}

	limited_inputs.forEach( limited_input => {
		const maxlength = limited_input.getAttribute( 'maxlength' );

		if ( !maxlength ) {
			return;
		}

		limited_input.addEventListener( 'input', () => {
			const chars_wrapper = limited_input.nextElementSibling.querySelector( '.js-input-chars' );

			if ( !chars_wrapper ) {
				return;
			}

			const char_count = limited_input.value.length;
			chars_wrapper.innerText = maxlength - char_count;
		} );

	} );

} )();

( () => {

	// Other amount - add class to show input text if radio is checked

	const other_amount_cards = document.querySelectorAll( '.js-other-amount' );

	if ( !other_amount_cards.length > 0 ) {
		return;
	}

	other_amount_cards.forEach( other_amount_card => {
		other_amount_card.addEventListener( 'click', () => {
			const donate_other = other_amount_card.querySelector( '[data-donate-other]' );
			const donate_amount = other_amount_card.querySelector( '[data-custom-amount]' );
			const other_status = donate_other.checked;

			if ( other_status === true ) {
				other_amount_card.classList.add( 'form__selector-card--other--open' );
				donate_amount.focus();
			} else {
				other_amount_card.classList.remove( 'form__selector-card--other--open' );
			}
		} );
	} );

	// Set amount - remove class to hide input on any other set amount cards

	const set_amount_cards = document.querySelectorAll( '.js-set-amount' );

	if ( !set_amount_cards.length > 0 ) {
		return;
	}

	set_amount_cards.forEach( set_amount_card => {
		set_amount_card.addEventListener( 'click', () => {
			other_amount_cards.forEach( other_amount_card => {
				other_amount_card.classList.remove( 'form__selector-card--other--open' );
			} );
		} );
	} );

} )();

( () => {

	// Ticket Selecter - Increase / decrease input value

	const ticket_selectors = document.querySelectorAll( '.form__ticket-selector__input-wrapper' );

	if ( !ticket_selectors.length > 0 ) {
		return;
	}

	ticket_selectors.forEach( ticket_selector => {
		const minus = ticket_selector.querySelector( '.form__ticket-selector__button--minus' );
		const add = ticket_selector.querySelector( '.form__ticket-selector__button--add' );
		const number_input = ticket_selector.querySelector( '.form__ticket-selector__input' );

		if ( !minus || !add || !number_input ) {
			return;
		}

		let min = 0;
		let max = null;

		if ( number_input.hasAttribute( 'min' ) ) {
			min = number_input.getAttribute( 'min' );
		}

		if ( number_input.hasAttribute( 'max' ) ) {
			max = number_input.getAttribute( 'max' );
		}

		minus.addEventListener( 'click', () => {
			const num = Number( number_input.value );
			if ( num > min ) {
				number_input.value = num - 1;
			}
		} );

		add.addEventListener( 'click', () => {
			const num = Number( number_input.value );
			if ( max == null || num < max ) {
				number_input.value = num + 1;
			}
		} );

	} );

} )();

( () => {

	// Show / hide form filter

	const form_filters = document.querySelectorAll( '.form__filter' );

	if ( !form_filters.length > 0 ) {
		return;
	}

	form_filters.forEach( form_filter => {
		const filter_button = form_filter.querySelector( '.form__filter__button' );
		const filter_inner = form_filter.querySelector( '.form__filter__bottom' );

		if ( !filter_button || !filter_inner ) {
			return;
		}

		filter_button.addEventListener( 'click', () => {
			if ( filter_button.innerHTML === 'Filter' ) {
				filter_button.innerHTML = 'Close filter';
				filter_inner.classList.add( 'form__filter__bottom--opening' );
				setTimeout( () => {
					filter_inner.classList.add( 'form__filter__bottom--open' );
					filter_inner.style.maxHeight = filter_inner.scrollHeight + 'px';
				}, 10 );

			} else {
				filter_button.innerHTML = 'Filter';
				filter_inner.classList.remove( 'form__filter__bottom--open' );
				filter_inner.style.maxHeight = null;
				setTimeout( () => {
					filter_inner.classList.remove( 'form__filter__bottom--opening' );
				}, 300 );
			}
		} );

	} );

} )();

function doToggle( toggler ) {
	const togglees = document.querySelectorAll( toggler.getAttribute( 'data-togglees' ) );

	for ( let i = 0; i < togglees.length; i += 1 ) {
		togglees[i].classList.toggle( 'toggled' );
	}
	toggler.classList.toggle( 'toggler--toggled' );
}

( () => {
	window.addEventListener( 'click', ( event ) => {
		if ( !event.target.classList.contains( 'toggler' ) ) {
			return;
		}
		doToggle( event.target );
	} );

	window.addEventListener( 'change', ( event ) => {
		if ( !event.target.classList.contains( 'change-toggler' ) ) {
			return;
		}
		doToggle( event.target );
	} );
} )();

( () => {
	// Async apply-discount-code button
	const buttons = document.querySelectorAll( '.button--apply-discount-code' );

	buttons.forEach( ( button ) => {
		if ( !button.getAttribute( 'data-discount-code-input' ) ) {
			return;
		}

		const discount_code_input = document.querySelector( button.getAttribute( 'data-discount-code-input' ) );

		if ( !discount_code_input ) {
			return;
		}

		button.addEventListener( 'click', () => {
			ajax( {
				type: 'POST',
				bodyType: 'JSON',
				headers: [
					['Content-Type', 'application/json'],
					['X-CSRF-Token', window.csrfToken]
				],
				data: {
					discount_code: discount_code_input.value
				},
				url: '?p=actions/cct/membership-create/set-discount-code',
				timeout: 8000,
				onSuccess: handleAjaxSuccess( button ),
				onError: handleAjaxError()
			} );
		} );
	} );

	function handleAjaxSuccess( button ) {
		return ( data ) => {
			window.dispatchEvent(
				new CustomEvent( 'discount-code-success', {
					detail: {
						discount_code_status: data,
					}
				} )
			);
			const data_object = JSON.parse( data );

			let discount_code_input = false;
			if ( button.getAttribute( 'data-discount-code-input' ) ) {
				discount_code_input = document.querySelector( button.getAttribute( 'data-discount-code-input' ) );
			}

			let payment_amount_elem = false;
			if ( button.getAttribute( 'data-payment-amount' ) ) {
				payment_amount_elem = document.querySelector( button.getAttribute( 'data-payment-amount' ) );
			}

			if ( payment_amount_elem ) {
				payment_amount_elem.innerText = '£' + parseFloat( data_object.total_value ).toFixed( 2 );
			}

			let discount_amount_elem = false;
			if ( button.getAttribute( 'data-discount-amount' ) ) {
				discount_amount_elem = document.querySelector( button.getAttribute( 'data-discount-amount' ) );
			}

			let included_amounts_conjunctive_elem = false;
			if ( button.getAttribute( 'data-included-amounts-conjunctive' ) ) {
				included_amounts_conjunctive_elem = document.querySelector( button.getAttribute( 'data-included-amounts-conjunctive' ) );
			}

			let included_amounts_elem = false;
			if ( button.getAttribute( 'data-included-amounts' ) ) {
				included_amounts_elem = document.querySelector( button.getAttribute( 'data-included-amounts' ) );
			}

			let boost_value_elem = false;
			if ( button.getAttribute( 'data-boost-value' ) ) {
				boost_value_elem = document.querySelector( button.getAttribute( 'data-boost-value' ) );
			}

			let error_container_elem = false;
			if ( button.getAttribute( 'data-error-container' ) && discount_code_input ) {
				error_container_elem = discount_code_input.parentElement.querySelector( button.getAttribute( 'data-error-container' ) );
			}

			if ( Object.keys( data_object ).includes( 'errors' ) ) {
				if ( discount_amount_elem ) {
					discount_amount_elem.classList.add( 'toggled' );
					discount_amount_elem.innerText = '';
				}
				if ( included_amounts_conjunctive_elem ) {
					included_amounts_conjunctive_elem.classList.add( 'toggled' );
				}
				if ( !boost_value_elem && included_amounts_elem ) {
					included_amounts_elem.classList.add( 'toggled' );
				}

				if ( error_container_elem ) {
					error_container_elem.innerHTML = '<span id="discount_code-error" class="form__error">' + data_object.errors[ 0 ] + '</span>';
				}
				button.innerHTML = 'Error applying discount code';
			} else {
				if ( included_amounts_elem && data_object.discount_amount > 0 && boost_value_elem === null ) {
					included_amounts_elem.classList.remove( 'toggled' );
				} else if ( !boost_value_elem && included_amounts_elem ) {
					included_amounts_elem.classList.add( 'toggled' );
				}
				if ( included_amounts_conjunctive_elem ) {
					if ( data_object.discount_amount > 0 && boost_value_elem ) {
						included_amounts_conjunctive_elem.classList.remove( 'toggled' );
					} else {
						included_amounts_conjunctive_elem.classList.add( 'toggled' );
					}
				}
				if ( discount_amount_elem ) {
					if ( data_object.discount_amount > 0 ) {
						discount_amount_elem.classList.remove( 'toggled' );
						discount_amount_elem.innerText = '£' + parseFloat( data_object.discount_amount ).toFixed( 2 ) + ' discount';
					} else {
						discount_amount_elem.classList.add( 'toggled' );
					}
				}

				if ( error_container_elem ) {
					error_container_elem.innerHTML = '';
				}

				if ( data_object.discount_amount > 0 ) {
					button.innerHTML = 'Discount code applied';
				} else {
					button.innerHTML = 'Discount code cleared';
				}
			}
		};
	}

	function handleAjaxError() {
		return () => {
			alert( 'There was a problem applying your discount code.' );
		};
	}
} )();

function syncOutput( element ) {
	const synced_outputs = document.querySelectorAll( element.getAttribute( 'data-synced-output-elems' ) );

	for ( let i = 0; i < synced_outputs.length; i += 1 ) {
		const output_to_sync = synced_outputs[i];

		let source_input = element;
		if ( element.getAttribute( 'data-source-input' ) ) {
			source_input = document.querySelector( element.getAttribute( 'data-source-input' ) );
		}

		let amount = 0;

		if ( source_input.value !== '' && typeof source_input.value !== 'undefined' ) {
			amount += parseFloat( source_input.value );
		}

		if ( !isNaN( amount ) && amount > -1 ) {
			if ( output_to_sync.getAttribute( 'data-decimals' ) ) {
				amount = amount.toFixed( output_to_sync.getAttribute( 'data-decimals' ) );
			}
			output_to_sync.innerText = '£' + amount;
		}
	}
}

( () => {
	window.addEventListener( 'change', ( event ) => {
		if ( !event.target.classList.contains( 'sync-output' ) ) {
			return;
		}
		syncOutput( event.target );
	} );
} )();

( () => {
	const fields = [
		{ element: 'address-search', field: '', mode: pca.fieldMode.SEARCH }, // eslint-disable-line no-undef
		{ element: 'address', field: 'Line1', mode: pca.fieldMode.POPULATE }, // eslint-disable-line no-undef
		{ element: 'townCity', field: 'City', mode: pca.fieldMode.POPULATE }, // eslint-disable-line no-undef
		{ element: 'county', field: 'Province', mode: pca.fieldMode.POPULATE }, // eslint-disable-line no-undef
		{ element: 'postcode', field: 'PostalCode', mode: pca.fieldMode.POPULATE }, // eslint-disable-line no-undef
		{ element: 'country', field: 'CountryName', mode: pca.fieldMode.COUNTRY } // eslint-disable-line no-undef
	];

	const loqate_control = new pca.Address( fields, { key: window.loqateKey } ); // eslint-disable-line no-undef, no-unused-vars

	loqate_control.listen( 'populate', () => {
		const input = document.querySelector( '#' + fields[ 0 ].element );
		if ( input.hasAttribute( 'data-toggler' ) ) {
			doToggle( document.querySelector( input.getAttribute( 'data-toggler' ) ) );
		}
	} );
} )();

( () => {
	// Async feeds
	function handleAjaxSuccess( feed_container ) {
		return function( data ) {
			feed_container.classList.add( 'external-feed--loaded' );
			feed_container.innerHTML = data;
		};
	}

	function handleAjaxError( feed_container ) {
		return function() {
			feed_container.classList.add( 'external-feed--loaded' );
			feed_container.innerHTML = '<p class="p">Unable to load ticket options. Please try again later.</p>';
		};
	}

	const feed_containers = document.querySelectorAll( '.external-feed' );

	if ( !feed_containers ) {
		return;
	}

	for ( let t = 0; t < feed_containers.length; t++ ) {
		const url = feed_containers[t].getAttribute( 'data-feed-url' );
		if ( !url ) {
			return;
		}
		ajax( {
			type: 'get',
			url: url,
			timeout: 8000,
			onSuccess: handleAjaxSuccess( feed_containers[t] ),
			onError: handleAjaxError( feed_containers[t] )
		} );
	}

} )();

( () => {
	const element = document.querySelector( '.select-tickets' );

	if ( !element ) {
		return;
	}

	selectTickets( element );
} )();

